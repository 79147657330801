import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminEmployee = () => {
  const navigate = useNavigate();
  const [emData, setData] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [istatus, setIsstaus] = useState(false);
  const [vsetval, setvall] = useState([]);
  const [valueroster, setvalueroster] = useState([]);
  const [valueclients, setvalueclients] = useState([]);
  const [notatus, setNostaus] = useState(false);
  const [gdata, getData] = useState("");
  const [rostertype, setrostertype] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isClickedclient, setisClickedclient] = useState(false);
  const [isClickedetails, setisClickedetails] = useState(false);
  const [isClickedyourroster, setisClickedyourroster] = useState(false);
  const [valuesickleave, setValuesickleave] = useState({});
  const [valueannualleave, setValueannualleave] = useState({});
  const [valueworkerscomp, setValueworkerscomp] = useState({});
  const [valueflyinpm, setValueflyinpm] = useState({});
  const [valueflyoutam, setValueflyoutam] = useState({});
  const [valueflyinam, setValueflyinam] = useState({});
  const [valueinisolationonsite, setValueinisolationonsite] = useState({});
  const [valueleavewithoutpay, setValueleavewithoutpay] = useState({});
  const [valueflyoutpm, setValueflyoutpm] = useState({});
  const [valueworkoffsite, setValueworkoffsite] = useState({});
  const [valuenightshift, setValuenightshift] = useState({});
  const [valueget, setValueget] = useState({});
  const [ValueDayoff, setValueDayoff] = useState({});

  const [Valueworkingdays, setValueworkingdays] = useState({});
  const [Valuealloffdays, setValuealloffdays] = useState({});

  const [valueemplyId, setvalueemplyId] = useState("");

  const [Valuemainroster, setValuemainroster] = useState({});

  const handlecheckApprove = (userid, email) => {
    console.log(userid);
    let user_id = {
      user_id: userid,
      email: email,
    };

    axios
      .post("https://backend.hssonline.com.au/admin/userApprove", user_id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsstaus(true);
        setNostaus(false);
        setShowAlert(true);
        setShowAlert({ type: "success" });
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      })
      .catch((err) => {});
  };
  const handleuserRemove = (userid) => {
    console.log(userid);
    let user_id = {
      user_id: userid,
    };
    const del = window.confirm("Are you sure you want to delete this user?");
    if (del) {
      axios
        .post("https://backend.hssonline.com.au/admin/userdelete", user_id, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setIsstaus(true);
          setNostaus(false);
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setIsClicked(false);
          setActiveIndex(null);
          axios
            .get("https://backend.hssonline.com.au/admin/getemployee", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  const handlegetClient = (userId, index) => {
    let formdata = {
      userId: userId,
    };
    setvalueclients([]);
    setvalueemplyId(userId);
    setActiveIndex(index);
    setisClickedyourroster(false);
    setisClickedetails(false);
    setValuealloffdays([]);
    setValueworkingdays([]);
    axios
      .post("https://backend.hssonline.com.au/admin/getuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row !== "") {
          getData(res.data.row[0]);
          setvall(JSON.parse(res.data.row[0].skills));
          var st = res.data.row[0].status;
          if (st === "Active") {
            setIsstaus(true);
            setNostaus(false);
          }
          if (st === "Inactive") {
            setIsstaus(false);
            setNostaus(true);
          }
          setIsClicked(true);
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    let formdataa = {
      user_id: userId,
    };
    setisClickedclient(true);
    axios
      .post("https://backend.hssonline.com.au/admin/getallrosters", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          setvalueroster(res.data.results);
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getallclients_admin",
        formdataa,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.results.length > 0) {
          setvalueclients(res.data.results);
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Employee Page";
    const userStr = JSON.parse(sessionStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://backend.hssonline.com.au/admin/getemployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  const handle_getallattendance = (event) => {
    let formdata = {
      user_id: valueemplyId,
      client_id: event.target.value,
    };
    console.log(formdata);
    if (event.target.value != "") {
      setisClickedetails(true);
      setisClickedyourroster(true);
    } else {
      setisClickedetails(false);
      setisClickedyourroster(false);
    }
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getrosterrelated",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data;
        if (alldata.row[0] != "") {
          console.log(alldata.row[0]);
          setrostertype(alldata.row[0].type);
          setValuemainroster(alldata.row[0]);
        }
      })
      .catch((err) => {});

    //For All Working Days
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendanceAllworkingDays",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueworkingdays(alldata);
      })
      .catch((err) => {});
    //For All Working Days

    //For All Working Days
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendanceAlloffDays",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValuealloffdays(alldata);
      })
      .catch((err) => {});
    //For All Working Days

    //For Sick Leave
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailsickLeave",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValuesickleave(alldata);
      })
      .catch((err) => {});
    //For Sick Leave

    //For Annual Leave
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailannualLeave",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueannualleave(alldata);
      })
      .catch((err) => {});
    //For Annual Leave

    //For Works Comp
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailworkerscomp",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueworkerscomp(alldata);
      })
      .catch((err) => {});
    //For Works Comp

    //For Fly In Pm
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailflyinpm",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueflyinpm(alldata);
      })
      .catch((err) => {});
    //For Fly In Pm

    //For Fly Out Am
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailflyoutam",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueflyoutam(alldata);
      })
      .catch((err) => {});
    //For Fly Out Am

    //For Fly in Am
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailflyinam",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueflyinam(alldata);
      })
      .catch((err) => {});
    //For Fly in Am

    //For Fly In Isolation
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailinisolationonsite",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueinisolationonsite(alldata);
      })
      .catch((err) => {});
    //For Fly In Isolation

    //For Leave Without pay
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailleavewithoutpay",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueleavewithoutpay(alldata);
      })
      .catch((err) => {});
    //For Leave Without pay

    //For Fly out PM
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailflyoutpm",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueflyoutpm(alldata);
      })
      .catch((err) => {});
    //For Fly out PM

    //For Work of Site
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailworkoffsite",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueworkoffsite(alldata);
      })
      .catch((err) => {});
    //For Work of Site

    //Check day off
    axios
      .post("https://backend.hssonline.com.au/admin/getuserdayoff", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.data;
        setValueDayoff(alldata);
      })
      .catch((err) => {});
    //Check day off

    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailForDay",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;

        setValueget(alldata);
      })
      .catch((err) => {});
    //For Night Shift

    axios
      .post(
        "https://backend.hssonline.com.au/admin/getAttendancedetailForNight",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var nightshift = res.data.data;
        //console.log(alldata);
        setValuenightshift(nightshift);
      })
      .catch((err) => {});
    //For Night Shift
  };
  const isObjectEmpty = Object.keys(valuenightshift).length === 0;
  const isObjectEmptyday = Object.keys(valueget).length === 0;
  const isObjectEmptysickleave = Object.keys(valuesickleave).length === 0;
  const isObjectEmptydayoff = Object.keys(ValueDayoff).length === 0;

  const isObjectEmptyannualleave = Object.keys(valueannualleave).length === 0;
  const isObjectEmptyworkerscomp = Object.keys(valueworkerscomp).length === 0;
  const isObjectEmptyflyinpm = Object.keys(valueflyinpm).length === 0;
  const isObjectEmptyflyoutam = Object.keys(valueflyoutam).length === 0;
  const isObjectEmptyflyinam = Object.keys(valueflyinam).length === 0;
  const isObjectEmptyinisolationonsite =
    Object.keys(valueinisolationonsite).length === 0;
  const isObjectEmptyleavewithoutpay =
    Object.keys(valueleavewithoutpay).length === 0;
  const isObjectEmptyflyoutpm = Object.keys(valueflyoutpm).length === 0;
  const isObjectEmptyworkoffsite = Object.keys(valueworkoffsite).length === 0;

  const isObjectEmptyworkingdays = Object.keys(Valueworkingdays).length === 0;
  const isObjectEmptyalldaysOff = Object.keys(Valuealloffdays).length === 0;
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row">
              <div className="float-end d-flex justify-content-end">
                <Link to="/admin/addemployee" className="addclient">
                  Add Employee
                </Link>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="User deleted successfully!"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Select Employee</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            className={`item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetClient(item.id, index)}
                          >
                            {item.first_name} {item.last_name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isClicked && (
                <div className="col-md-8">
                  {showAlert?.type === "success" && (
                    <Alert
                      type="success"
                      description="Successfully approved!"
                      closable
                    />
                  )}

                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Employee Name:-</label>
                          <span className="fs-14">
                            {gdata.first_name} {gdata.last_name}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Phone Number:-</label>
                          <span className="fs-14">{gdata.contact}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Email-Id:-</label>
                          <span className="fs-14">{gdata.email}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Address:-</label>
                          <span className="fs-14">{gdata.address}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Skills:-</label>
                          <span className="fs-14">
                            {Array.isArray(vsetval)
                              ? vsetval.join(", ")
                              : "Loading..."}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Years Of Experience:-</label>
                          <span className="fs-14">{gdata.years}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          {notatus && (
                            <Link
                              title="Approve"
                              to="javascript:void(0)"
                              onClick={() =>
                                handlecheckApprove(gdata.id, gdata.email)
                              }
                              className="addclient"
                            >
                              Approve
                            </Link>
                          )}
                          {istatus && (
                            <Link
                              title="Approved"
                              to="javascript:void(0)"
                              className="addclientapp"
                            >
                              Approved
                            </Link>
                          )}
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="addclient"
                            onClick={() => handleuserRemove(gdata.id)}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div class="col-6 col-lg-6">
                        <h3 className="text-black fs-24 fw-bold">
                          Your Roster
                        </h3>
                        <select onChange={handle_getClient}>
                          <option value="">--Select Roster--</option>
                          {valueroster.map((valueroster) => (
                            <option
                              key={valueroster.user_id}
                              value={valueroster.user_id}
                            >
                              {valueroster.type}
                            </option>
                          ))}
                        </select>
                      </div>
                      {isClickedclient && (
                        <div class="col-6 col-lg-6">
                          <h3 className="text-black fs-24 fw-bold">Client</h3>
                          <select onChange={handle_getallattendance}>
                            <option value="">--Select Client--</option>
                            {valueclients.map((valueclients) => (
                              <option
                                key={valueclients.client_id}
                                value={valueclients.client_id}
                              >
                                {valueclients.cname}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              )}
            </div>

            {/** */}
            <div className="row gy-4 mt-4">
              <div className="col-md-4"></div>
              {isClickedclient && (
                <div className="col-md-8">
                  <Link to="javascript:void(0)" className="addclient">
                    Client
                  </Link>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <select
                        className="form-control"
                        onChange={handle_getallattendance}
                      >
                        <option value="">--Select Client--</option>
                        {valueclients.map((valueclients) => (
                          <option
                            key={valueclients.client_id}
                            value={valueclients.client_id}
                          >
                            {valueclients.cname}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      {isClickedyourroster && (
                        <span className="p-2 mt-4 ">
                          Your Roster is <b>{rostertype}</b>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="right-form-box bg-white shadow p-2 mt-4">
                    <div className="d-flex flex-column gap-4 heightprofile">
                      <div className="row">
                        <div className="col-3">
                          <h4 className="text-black fs-16">Working Days:</h4>
                        </div>
                        <div className="col-9">
                          {isObjectEmptyworkingdays ? (
                            <p>No results found.</p>
                          ) : (
                            <div>
                              {Object.keys(Valueworkingdays).map((month) => (
                                <div className="d-flex flex-column" key={month}>
                                  <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                    <div className="flex-grow-1">
                                      <div className="scroll-date-block">
                                        <div className="d-flex gap-1">
                                          {Valueworkingdays[month].map(
                                            (row) => (
                                              <h5 key={row.id}>{row.nd}</h5>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h5 className="text-black fs-14">
                                        {month}/
                                        {Valueworkingdays[month][0].year % 100}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-3">
                          <h4 className="text-black fs-16">Day Off:</h4>
                        </div>
                        <div className="col-9">
                          {isObjectEmptyalldaysOff ? (
                            <p>No results found.</p>
                          ) : (
                            <div>
                              {Object.keys(Valuealloffdays).map((month) => (
                                <div className="d-flex flex-column" key={month}>
                                  <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                    <div className="flex-grow-1">
                                      <div className="scroll-date-block">
                                        <div className="d-flex gap-1">
                                          {Valuealloffdays[month].map((row) => (
                                            <h5 key={row.id}>{row.nd}</h5>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h5 className="text-black fs-14">
                                        {month}/
                                        {Valuealloffdays[month][0].year % 100}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isClickedyourroster && (
                      <div>
                        {
                          Valuemainroster ? (
                            <p>
                              <b>
                                {" "}
                                Current Roster: {gdata.first_name}{" "}
                                {gdata.last_name}{" "}
                              </b>
                              Roster from <b>{Valuemainroster.cname} </b> and{" "}
                              <b> {Valuemainroster.location_name}</b>
                            </p>
                          ) : null // or any fallback content you want to render when Valuemainroster is falsy
                        }
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
