import React, { useState, useEffect } from "react";
//import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  HashRouter as Router,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import logo from "./logo.svg";
import "./App.css";
import { Login } from "./Login";
import { Register } from "./Register";
import { Syn } from "./Syn";
import { Home } from "./Home";
import { Roster } from "./Roster";
import { Profile } from "./Profile";
import { Logout } from "./Logout";
import { Cl } from "./Cl";
import { Currentroster } from "./Currentroster";
import { Timesheet } from "./Timesheet";
import { Forgotpassword } from "./Forgotpassword";
import { Clientinfo } from "./Clientinfo";
import { Contact } from "./Contact";
import { Experthelp } from "./Experthelp";
import { Privacypolicy } from "./Privacypolicy";
import { Termscondition } from "./Termscondition";
import { AdminLogin } from "./Admin/AdminLogin";
import { AdminNotification } from "./Admin/AdminNotification";
import { AdminClient } from "./Admin/AdminClient";
import { AdminAddclient } from "./Admin/AdminAddclient";
import { AdminEmployee } from "./Admin/AdminEmployee";
import { AdminAddemployee } from "./Admin/AdminAddemployee";
import { AdminLocation } from "./Admin/AdminLocation";
import { AdminAddlocation } from "./Admin/AdminAddlocation";
import { AdminCalendar } from "./Admin/AdminCalendar";
import { AdminTimesheet } from "./Admin/AdminTimesheet";
import { AdminAddskill } from "./Admin/AdminAddskill";
import { AdminLogout } from "./Admin/AdminLogout";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Modal from "react-modal";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom-style.css";
import "./assets/css/style.css";
import "./assets/css/otherstyle.css";

function App() {
  //const [currentForm, setCurrentForm] = useState("login");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      setUser(JSON.parse(userStr));
    }
  }, []);
  // console.log(user);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/forgotpassword" element={<Forgotpassword />}></Route>
        <Route path="/cl" element={<Cl />}></Route>
        <Route path="/syn" element={<Syn />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/experthelp" element={<Experthelp />}></Route>
        <Route path="/terms&condition" element={<Termscondition />}></Route>
        <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
        <Route path="/newroster" element={<Roster />}></Route>
        <Route path="/timesheet" element={<Timesheet />}></Route>
        <Route path="/currentroster" element={<Currentroster />}></Route>
        <Route path="/clinetinformation" element={<Clientinfo />}></Route>
        <Route path="/admin/login" element={<AdminLogin />}></Route>
        <Route path="/admin/addskill" element={<AdminAddskill />}></Route>
        <Route path="/admin/logout" element={<AdminLogout />}></Route>
        <Route
          path="/admin/notification"
          element={<AdminNotification />}
        ></Route>
        <Route path="/admin/employee" element={<AdminEmployee />}></Route>
        <Route path="/admin/location" element={<AdminLocation />}></Route>
        <Route path="/admin/timesheet" element={<AdminTimesheet />}></Route>
        <Route path="/admin/calendar" element={<AdminCalendar />}></Route>
        <Route path="/calendar" element={<Calendar />}></Route>
        <Route path="/admin/client" element={<AdminClient />}></Route>
        <Route path="/admin/addlocation" element={<AdminAddlocation />}></Route>
        <Route path="/admin/addclient" element={<AdminAddclient />}></Route>
        <Route path="/admin/addemployee" element={<AdminAddemployee />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
