import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminClient = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [gdata, getData] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleuserRemove = (clientid) => {
    console.log(clientid);
    let client_id = {
      client_id: clientid,
    };
    const del = window.confirm("Are you sure you want to delete this client?");
    if (del) {
      axios
        .post(
          "https://backend.hssonline.com.au/admin/clientdelete",
          client_id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // setIsstaus(true);
          // setNostaus(false);
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setIsClicked(false);
          setActiveIndex(null);
          axios
            .get("https://backend.hssonline.com.au/admin/getclient", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  const handlegetClient = (clientId, index) => {
    let formdata = {
      clientId: clientId,
    };
    setActiveIndex(index);
    axios
      .post("https://backend.hssonline.com.au/admin/getclient", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        getData(res.data.row[0]);
        setIsClicked(true);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Client Page";
    const userStr = JSON.parse(sessionStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://backend.hssonline.com.au/admin/getclient", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row">
              <div className="float-end d-flex justify-content-end">
                <Link to="/admin/addclient" className="addclient">
                  Add Client
                </Link>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Client deleted successfully!"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Select Client</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(data) ? (
                        data.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            className={`item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetClient(item.id, index)}
                          >
                            {item.name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isClicked && (
                <div className="col-md-8">
                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Client Name:-</label>
                          <span className="fs-14">{gdata.name}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Position:-</label>
                          <span className="fs-14">{gdata.position}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Department:-</label>
                          <span className="fs-14">{gdata.department}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Work Phone Number:-</label>
                          <span className="fs-14">{gdata.phone_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Mobile Phone Number:-</label>
                          <span className="fs-14">{gdata.mobile_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Home Phone Number:-</label>
                          <span className="fs-14">
                            {gdata.home_phone_number}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Fax Number:-</label>
                          <span className="fs-14">{gdata.fax_number}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Email-Id:-</label>
                          <span className="fs-14">{gdata.email}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="addclient"
                            onClick={() => handleuserRemove(gdata.id)}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
