import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
export const Header = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [showssheader, setshowssheader] = useState(false);
  const [showsisClickedopen, setshowsisClickedopen] = useState(true);

  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const isMobileScreen = window.innerWidth <= 767.98;
  const [isDivVisible, setDivVisibility] = useState(!isMobileScreen);
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    console.log(userStr);
    console.log(";ss");
    const handleResize = () => {
      const isMobile = window.innerWidth <= 767.98;
      if (isMobile !== isDivVisible) {
        setDivVisibility(!isMobile);
      }
    };
    if (userStr === null) {
      setshowssheader(false);
      document.title = "Home Page";
    } else {
      setshowssheader(true);
      let formdata = {
        user_id: userStr[0].id,
      };
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getuserdetails",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.row[0];

          setValuegetuser(alldata);
        })
        .catch((err) => {});
    }
  }, []);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });

  const mobilepopup = () => {
    setshowsisClickedopen(!showsisClickedopen);
  };
  const fname = valueUserDetail.first_name;
  const mname = valueUserDetail.middle_name;
  const lname = valueUserDetail.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  const location = useLocation();
  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
  };
  return (
    <>
      <header
        style={{ position: "relative", left: "0", right: "0" }}
        className="d-none d-sm-block"
      >
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
            </div>

            <div className="col-9 col-md-6">
              {showssheader && (
                <div className="navbar-list align-items-center  d-flex gap-3 justify-content-end">
                  <Link
                    to="/home"
                    className={location.pathname === "/home" ? "active" : ""}
                  >
                    Home
                  </Link>
                  <Link
                    to="/profile"
                    className={location.pathname === "/profile" ? "active" : ""}
                  >
                    Profile
                  </Link>
                  {/* <Link
                    to="/newroster"
                    className={
                      location.pathname === "/newroster" ? "active" : ""
                    }
                  >
                    Roster
                  </Link> */}
                  <Link
                    to="/currentroster"
                    className={
                      location.pathname === "/currentroster" ? "active" : ""
                    }
                  >
                    Current Roster
                  </Link>
                  <Link
                    to="/timesheet"
                    className={
                      location.pathname === "/timesheet" ? "active" : ""
                    }
                  >
                    Signature
                  </Link>
                  <Link
                    to="/clinetinformation"
                    className={
                      location.pathname === "/clinetinformation" ? "active" : ""
                    }
                  >
                    Client Information
                  </Link>
                  <p className="user-text-icon">
                    {fLetter} {mLetter} {lLetter}
                  </p>
                  <Link className="logout" to="/logout">
                    Logout
                  </Link>
                </div>
              )}
              {!showssheader && (
                <div className="navbar-list align-items-center  d-flex gap-3 justify-content-end">
                  <Link to="/login" className="active">
                    Login
                  </Link>
                  <Link to="/register">Register</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      {/* mobile header */}

      <header
        style={{ position: "relative", left: "0", right: "0" }}
        className="d-block d-sm-none"
      >
        <div
          className="navbar-top"
          style={{ display: isDivVisible ? "block" : "none" }}
        >
          <div className="d-flex flex-column">
            <div className="nav-link-main d-flex flex-column">
              <div className="navbar-list  justify-content-end d-flex flex-column">
                <Link
                  to="/home"
                  className={
                    location.pathname === "/home" ? "active fs-6" : "fs-6"
                  }
                >
                  Home
                </Link>
                <Link
                  to="/profile"
                  className={
                    location.pathname === "/profile" ? "active fs-6" : "fs-6"
                  }
                >
                  Profile
                </Link>
                {/* <Link
                  to="/newroster"
                  className={location.pathname === "/newroster" ? "active" : ""}
                >
                  Roster
                </Link> */}
                <Link
                  to="/currentroster"
                  className={
                    location.pathname === "/currentroster"
                      ? "active fs-6"
                      : "fs-6"
                  }
                >
                  Current Roster
                </Link>
                <Link
                  to="/timesheet"
                  className={
                    location.pathname === "/timesheet" ? "active fs-6" : "fs-6"
                  }
                >
                  Signature
                </Link>
                <Link
                  to="/clinetinformation"
                  className={
                    location.pathname === "/clinetinformation"
                      ? "active fs-6"
                      : "fs-6"
                  }
                >
                  Client Information
                </Link>
                <Link className="" to="/logout">
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <div class="d-flex justify-content-between  align-items-center">
              <div className="col-2 nav-mob">
                <button type="button" onClick={toggleDivVisibility}>
                  <i className="fal fa-bars"></i>
                </button>
              </div>
              <Link to="#" className="logo-top col-3 px-2 offset-1">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
              <Link to="#" className="logo-top col-3 px-2">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
              <div className="col-2 ms-auto">
                <div className="navbar-list float-end">
                  <p className="user-text-icon">
                    {fLetter} {mLetter} {lLetter}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* 
        <div className="container-lg">
          <div className="row gy-3 align-items-center justify-content-between">
            <div className="col-4">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-4">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>

            <div className="col-8"></div>
            <div className="col-12"></div>
          </div>
        </div> */}
      </header>
    </>
  );
};
