import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import Select from "react-select";
import { Alert } from "antd";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Profile = () => {
  const currentYear = new Date().getFullYear();
  const [valueget, setValueget] = useState({});
  const [setVal, setValues] = useState({});
  const [clienttype, setClientType] = useState("");
  const [clientname, setClientName] = useState("");
  const [clientMinesite, setClientMinesite] = useState("");
  const [vsetval, setvalll] = useState([]);
  const [setoptionskillvalue, setoptionskill] = useState([]);
  const [valuesickleave, setValuesickleave] = useState({});
  const [valueannualleave, setValueannualleave] = useState({});
  const [valueworkerscomp, setValueworkerscomp] = useState({});
  const [valueflyinpm, setValueflyinpm] = useState({});
  const [valueflyoutam, setValueflyoutam] = useState({});
  const [valueflyinam, setValueflyinam] = useState({});
  const [valueinisolationonsite, setValueinisolationonsite] = useState({});
  const [valueleavewithoutpay, setValueleavewithoutpay] = useState({});
  const [valueflyoutpm, setValueflyoutpm] = useState({});
  const [valueworkoffsite, setValueworkoffsite] = useState({});
  const [valuenightshift, setValuenightshift] = useState({});
  const [valuegetUser, setValuegetuser] = useState([]);
  const [valuegetClient, setValuegetclient] = useState([]);
  const [ValueDayoff, setValueDayoff] = useState({});
  const [valset, setval] = useState("");
  const isActive = true;
  const [selectvalue, setselectvalue] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const lastTwoDigits = currentYear % 100;
  const [indx, setindx] = useState(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const apiurl = "https://backend.hssonline.com.au/uploads/";
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    document.title = "Profile Page";
    if (userStr === null) {
      window.location.href = "#/login";
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    let formdataa = {
      user_id: 1,
    };
    axios
      .post("https://backend.hssonline.com.au/getskills", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);

        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    setindx(0);
    axios
      .post("https://backend.hssonline.com.au/admin/getuserdetails", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        setvalll(JSON.parse(alldata.skills));

        const inputString = JSON.parse(alldata.skills);

        console.log(inputString);
        console.log("sas");

        const optionss = inputString.map((item) => ({
          value: item,
          label: item,
        }));
        //console.log(optionss);

        setoptionskill(optionss);

        setValuegetuser(alldata);
      })
      .catch((err) => {});
    axios
      .post("https://backend.hssonline.com.au/admin/getclientFuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        if (alldata.length > 0) {
          let formdata = {
            user_id: userStr[0].id,
            client_id: alldata[0].client_id,
          };

          setClientType(alldata[0].type);
          setClientName(alldata[0].name);
          setClientMinesite(alldata[0].location_name);
          // console.log(alldata);
          //For Sick Leave
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailsickLeave",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValuesickleave(alldata);
            })
            .catch((err) => {});
          //For Sick Leave

          //For Annual Leave
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailannualLeave",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueannualleave(alldata);
            })
            .catch((err) => {});
          //For Annual Leave

          //For Works Comp
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailworkerscomp",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueworkerscomp(alldata);
            })
            .catch((err) => {});
          //For Works Comp

          //For Fly In Pm
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailflyinpm",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueflyinpm(alldata);
            })
            .catch((err) => {});
          //For Fly In Pm

          //For Fly Out Am
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailflyoutam",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueflyoutam(alldata);
            })
            .catch((err) => {});
          //For Fly Out Am

          //For Fly in Am
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailflyinam",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueflyinam(alldata);
            })
            .catch((err) => {});
          //For Fly in Am

          //For Fly In Isolation
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailinisolationonsite",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueinisolationonsite(alldata);
            })
            .catch((err) => {});
          //For Fly In Isolation

          //For Leave Without pay
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailleavewithoutpay",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueleavewithoutpay(alldata);
            })
            .catch((err) => {});
          //For Leave Without pay

          //For Fly out PM
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailflyoutpm",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueflyoutpm(alldata);
            })
            .catch((err) => {});
          //For Fly out PM

          //For Work of Site
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailworkoffsite",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueworkoffsite(alldata);
            })
            .catch((err) => {});
          //For Work of Site

          //Check day off
          axios
            .post(
              "https://backend.hssonline.com.au/admin/getuserdayoff",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              console.log("checkd");
              console.log(formdata);
              setValueDayoff(alldata);
            })
            .catch((err) => {});
          //Check day off

          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailForDay",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;

              setValueget(alldata);
            })
            .catch((err) => {});
          setValuegetclient(alldata);
          //For Night Shift

          axios
            .post(
              "https://backend.hssonline.com.au/admin/getAttendancedetailForNight",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var nightshift = res.data.data;
              //console.log(alldata);
              setValuenightshift(nightshift);
            })
            .catch((err) => {});
          //For Night Shift
        }
      })
      .catch((err) => {});
  }, []);

  const handlegetClientdata = (client_id, index) => {
    setindx(index);
    var client_id = client_id;
    //All Array
    setValueDayoff([]);
    setValuenightshift([]);
    setValueworkoffsite([]);
    setValueflyoutpm([]);
    setValueleavewithoutpay([]);
    setValueinisolationonsite([]);
    setValueflyinam([]);
    setValueflyoutam([]);
    setValueflyinpm([]);
    setValueworkerscomp([]);
    setValueannualleave([]);
    setValuesickleave([]);
    //All Array
    setTimeout(function () {
      const userStr = JSON.parse(sessionStorage.getItem("users"));
      let formdata = {
        user_id: userStr[0].id,
        client_id: client_id,
      };
      console.log(formdata);
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailForDay",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);

          setValueget(alldata);
        })
        .catch((err) => {});
      //get client
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getclientroster",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.row;
          console.log("sep");
          console.log(alldata);
          setClientType(alldata[0].type);
          setClientName(alldata[0].name);
          setClientMinesite(alldata[0].location_name);
        })
        .catch((err) => {});
      //get client

      //For Sick Leave
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailsickLeave",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValuesickleave(alldata);
        })
        .catch((err) => {});
      //For Sick Leave
      //For Annual Leave
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailannualLeave",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueannualleave(alldata);
        })
        .catch((err) => {});
      //For Annual Leave

      //For Works Comp
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailworkerscomp",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueworkerscomp(alldata);
        })
        .catch((err) => {});
      //For Works Comp

      //For Fly In Pm
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailflyinpm",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueflyinpm(alldata);
        })
        .catch((err) => {});
      //For Fly In Pm

      //For Fly Out Am
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailflyoutam",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueflyoutam(alldata);
        })
        .catch((err) => {});
      //For Fly Out Am

      //For Fly in Am
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailflyinam",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueflyinam(alldata);
        })
        .catch((err) => {});
      //For Fly in Am

      //For Fly In Isolation
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailinisolationonsite",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueinisolationonsite(alldata);
        })
        .catch((err) => {});
      //For Fly In Isolation

      //For Leave Without pay
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailleavewithoutpay",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueleavewithoutpay(alldata);
        })
        .catch((err) => {});
      //For Leave Without pay

      //For Fly out PM
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailflyoutpm",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueflyoutpm(alldata);
        })
        .catch((err) => {});
      //For Fly out PM

      //For Work of Site
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailworkoffsite",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueworkoffsite(alldata);
        })
        .catch((err) => {});
      //For Work of Site
      setValueDayoff([]);

      //Check day off
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getuserdayoff",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueDayoff(alldata);
        })
        .catch((err) => {});
      //Check day off

      //For Night Shift
      axios
        .post(
          "https://backend.hssonline.com.au/admin/getAttendancedetailForNight",
          formdata,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var nightshift = res.data.data;
          setValuenightshift(nightshift);
        })
        .catch((err) => {});
      //For Night Shift
    }, 500);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const numbers = Array.from({ length: 31 }, (_, index) => index + 1);

  const isObjectEmpty = Object.keys(valuenightshift).length === 0;
  const isObjectEmptyday = Object.keys(valueget).length === 0;
  const isObjectEmptysickleave = Object.keys(valuesickleave).length === 0;
  const isObjectEmptydayoff = Object.keys(ValueDayoff).length === 0;

  const isObjectEmptyannualleave = Object.keys(valueannualleave).length === 0;
  const isObjectEmptyworkerscomp = Object.keys(valueworkerscomp).length === 0;
  const isObjectEmptyflyinpm = Object.keys(valueflyinpm).length === 0;
  const isObjectEmptyflyoutam = Object.keys(valueflyoutam).length === 0;
  const isObjectEmptyflyinam = Object.keys(valueflyinam).length === 0;
  const isObjectEmptyinisolationonsite =
    Object.keys(valueinisolationonsite).length === 0;
  const isObjectEmptyleavewithoutpay =
    Object.keys(valueleavewithoutpay).length === 0;
  const isObjectEmptyflyoutpm = Object.keys(valueflyoutpm).length === 0;
  const isObjectEmptyworkoffsite = Object.keys(valueworkoffsite).length === 0;

  const [values, setValuess] = useState({});
  const [vall, setVall] = useState("");
  const handleInputChange = (event) => {
    setVall((v) => (event.target.validity.valid ? event.target.value : v));
    setValuess((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handlerole_update = (event) => {
    //return false;

    const userStr = JSON.parse(sessionStorage.getItem("users"));

    let formData = {
      role: event.target.role.value,
      user_id: userStr[0].id,
    };

    axios
      .post("https://backend.hssonline.com.au/admin/userroleupdate", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {});
  };
  const handleprofile_update = (event) => {
    console.log();
    event.preventDefault();

    const formData = new FormData();
    // Perform actions with the selected file, like uploading to a server
    console.log("Selected file:", selectedFile);

    const userStr = JSON.parse(sessionStorage.getItem("users"));
    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }
    if (selectvalue.length == "0") {
      if (setoptionskillvalue.length > 0) {
        for (let i = 0; i < setoptionskillvalue.length; i++) {
          console.log(setoptionskillvalue[i]);
          skidata.push(setoptionskillvalue[i].value);
        }
      }
    }

    formData.append("user_id", userStr[0].id); // Adding user_id field
    formData.append("file", selectedFile);
    formData.append("first_name", event.target.first_name.value);
    formData.append("profiledate", event.target.profiledate.value);
    formData.append("last_name", event.target.last_name.value);
    formData.append("middle_name", event.target.middle_name.value);
    formData.append("email", event.target.email.value);
    formData.append("contact", event.target.contact.value);
    formData.append("address", event.target.address.value);
    formData.append("skills", JSON.stringify(skidata));
    axios
      .post(
        "https://backend.hssonline.com.au/admin/userprofileupdate",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {});
  };
  const profileupdate = (event) => {
    setIsModalOpen(true);
    const options = selectedOptions;
    // console.log(options);
    const handleSelectChange = (selectedOptions) => {
      console.log(selectedOptions);
      setselectvalue(selectedOptions);
      //setSelectedOptions(selectedOptions);
    };

    const handleRemoveOption = (removedOption) => {
      setSelectedOptions((prevSelected) =>
        prevSelected.filter((option) => option.value !== removedOption.value)
      );
    };

    const selectedOptionLabels = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );
  };
  const editrole = (event) => {
    setIsModalOpenRole(true);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  var imageUrl = `${apiurl}${valuegetUser.image}`;
  if (valuegetUser.image == null || valuegetUser.image == "") {
    var im = "male-upload.png";
    var imageUrl = `${apiurl}${im}`;
  } else {
    var imageUrl = `${apiurl}${valuegetUser.image}`;
  }
  const options = selectedOptions;
  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions);
    setselectvalue(selectedOptions);
    //setSelectedOptions(selectedOptions);
  };

  const handleRemoveOption = (removedOption) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option.value !== removedOption.value)
    );
  };

  const selectedOptionLabels = selectedOptions.map(
    (selectedOption) => selectedOption.value
  );
  const fname = valuegetUser.first_name;
  const mname = valuegetUser.middle_name;
  const lname = valuegetUser.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">Profile</h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-4 col-lg-3">
              <div className="d-flex flex-column gap-2 text-center pic-emp">
                <div className="emplo-box-pic ofit mx-auto">
                  <img src={imageUrl} alt="image" />
                </div>
                <div className="d-flex flex-column">
                  <Link
                    to="javascript:void(0)"
                    title="Upload Profile"
                    onClick={() => profileupdate()}
                  >
                    <i className="fas fa-edit" style={{ color: "red" }}></i>
                  </Link>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Add Text Modal"
                    className="profile d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                  >
                    {" "}
                    <form
                      action="javascript:void(0)"
                      onSubmit={handleprofile_update}
                      method="post"
                    >
                      <div className="d-flex align-item-center justify-content-between mb-4">
                        <h4> Update Profile </h4>
                        <button className="btn btn-success " type="submit">
                          Submit
                        </button>
                      </div>

                      {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                      <div className="form-scroll-profile">
                        <div class="row">
                          {showAlert?.type === "success" && (
                            <Alert
                              type="success"
                              description="Profile has been updated successfully!"
                              closable
                            />
                          )}
                          {showAlert?.type === "error" && (
                            <Alert
                              type="error"
                              description="Email is already exists!"
                              closable
                            />
                          )}
                          <div class="col-md-6 mt-4">
                            <label className="mb-2">First Name</label>
                            <input
                              type="text"
                              name="first_name"
                              required
                              defaultValue={valuegetUser.first_name}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6 mt-4">
                            <label className="mb-2">Middle Name</label>
                            <input
                              type="text"
                              name="middle_name"
                              defaultValue={valuegetUser.middle_name}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Last Name</label>
                            <input
                              type="text"
                              name="last_name"
                              required
                              defaultValue={valuegetUser.last_name}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Email</label>
                            <input
                              type="email"
                              name="email"
                              required
                              defaultValue={valuegetUser.email}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Contact</label>
                            <input
                              type="text"
                              name="contact"
                              required
                              pattern="[0-9]*"
                              minlength="10"
                              defaultValue={valuegetUser.contact}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Address</label>
                            <input
                              type="text"
                              name="address"
                              required
                              defaultValue={valuegetUser.address}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6 mb-3">
                            <label className="mb-2">Mention your Skills</label>
                            <div className="form-box rel">
                              <Select
                                isMulti
                                options={options}
                                defaultValue={setoptionskillvalue}
                                name="skills"
                                required
                                onChange={handleSelectChange}
                              />
                              <div></div>
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label className="mb-2">Upload Image</label>
                            <input
                              type="file"
                              name="image"
                              accept=".jpg, .png, .gif, .jpeg"
                              onChange={handleFileChange}
                              className="form-control mb-2"
                            />
                            <input
                              type="hidden"
                              name="profiledate"
                              defaultValue={valuegetUser.image}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </Modal>
                  <h3 className="fs-26 text-black fw-800">
                    {valuegetUser.first_name} {valuegetUser.middle_name}{" "}
                    {valuegetUser.last_name}
                  </h3>
                  <Modal
                    isOpen={isModalOpenRole}
                    onRequestClose={() => setIsModalOpenRole(false)}
                    contentLabel="Add Text Modal"
                    className="role d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                  >
                    {" "}
                    <form
                      action="javascript:void(0)"
                      onSubmit={handlerole_update}
                      method="post"
                    >
                      <div className="d-flex align-item-center justify-content-between mb-4">
                        <h4> Update Role </h4>
                      </div>

                      {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                      <div class="row">
                        {showAlert?.type === "success" && (
                          <Alert
                            type="success"
                            description="Profile has been updated successfully!"
                            closable
                          />
                        )}
                        {showAlert?.type === "error" && (
                          <Alert
                            type="error"
                            description="Email is already exists!"
                            closable
                          />
                        )}

                        <div class="col-md-12">
                          <label className="mb-2">Role</label>
                          <input
                            type="text"
                            name="role"
                            required
                            defaultValue={valuegetUser.role}
                            onChange={handleInputChange}
                            className="form-control mb-2"
                          />
                          <button
                            className="btn btn-success w-50"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                  <Link
                    to="javascript:void(0)"
                    title="Edit Role"
                    onClick={() => editrole()}
                  >
                    <i className="fas fa-edit" style={{ color: "red" }}></i>
                  </Link>
                  <h4 className="fs-18 text-black">
                    <b>{valuegetUser.role}</b>
                  </h4>
                  {/* <br/>
                  <p className="fs-12 text-black">{valuegetUser.years}</p> */}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="row gy-2 emplo-textblock">
                <div className="col-md-6">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Phone no: </span>
                    {valuegetUser.contact}
                  </h3>
                </div>
                <div className="col-md-6">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Email id: </span>
                    {valuegetUser.email}
                  </h3>
                </div>
                <div className="col-12">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Address: </span>
                    {valuegetUser.address}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3">
              <div className="d-flex flex-column gap-1">
                <h3 className="text-black fs-24 fw-bold mb-3">
                  You are Working:
                </h3>

                <div className="d-flex flex-column gap-1">
                  {Array.isArray(valuegetClient) ? (
                    valuegetClient.map((item, index) => (
                      <Link
                        to="javascript:void(0)"
                        onClick={() =>
                          handlegetClientdata(item.client_id, index)
                        }
                        className={`client-btn-text d-block ${
                          index === indx && isActive ? "client-active" : ""
                        }`}
                      >
                        {item.name}
                      </Link>
                    ))
                  ) : (
                    <Link> No data available </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column mb-2">
                  <h3 className="text-black fs-20 fw-600">
                    Your Roster for {clientname} and {clientMinesite}
                  </h3>
                  <h4 className="text-black fs-16 fw-400">
                    Your Roster is {clienttype}
                  </h4>
                </div>
                <div className="d-flex flex-column gap-4 heightprofile">
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Day shifts:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyday ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueget).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueget[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueget[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Night shifts:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmpty ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valuenightshift).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valuenightshift[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valuenightshift[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Day Off:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptydayoff ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(ValueDayoff).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {ValueDayoff[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{ValueDayoff[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Sick Leave:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptysickleave ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valuesickleave).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valuesickleave[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valuesickleave[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*******Annual Leave */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Annual Leave:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyannualleave ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueannualleave).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueannualleave[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueannualleave[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*******Annual Leave */}

                  {/*****Work Camp */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Workers Camp:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyworkerscomp ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueworkerscomp).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueworkerscomp[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueworkerscomp[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Work Camp */}

                  {/*****Fly In Pm */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly In PM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyinpm ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyinpm).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyinpm[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyinpm[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Fly In Pm */}

                  {/*****Fly Out Pm */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly Out AM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyoutam ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyoutam).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyoutam[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyoutam[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Fly Out Pm */}

                  {/*****Fly In Am */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly In AM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyinam ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyinam).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyinam[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyinam[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Fly In Am */}

                  {/*****In Isolation On Site */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">
                        In Isolation On Site:
                      </h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyinisolationonsite ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueinisolationonsite).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueinisolationonsite[month].map(
                                        (row) => (
                                          <h5 key={row.id}>{row.nd}</h5>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueinisolationonsite[month][0].year %
                                      100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****In Isolation On Site */}

                  {/*****Leave Without Pay */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Leave Without Pay:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyleavewithoutpay ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueleavewithoutpay).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueleavewithoutpay[month].map(
                                        (row) => (
                                          <h5 key={row.id}>{row.nd}</h5>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueleavewithoutpay[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Leave Without Pay */}

                  {/*****FlyOut PM */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly Out PM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyoutpm ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyoutpm).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyoutpm[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyoutpm[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****FlyOut PM */}

                  {/*****Work Of Site */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Work Offsite:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyworkoffsite ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueworkoffsite).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueworkoffsite[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueworkoffsite[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Work Of Site */}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex flex-column gap-1">
                <h3 className="text-black fs-24 fw-bold mb-3">Skills:</h3>

                <div className="d-flex flex-wrap gap-1">
                  <h5 className="sikll-text-head d-block text-black">
                    {vsetval.join(", ")}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
