import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
export const Register = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectvalue, setselectvalue] = useState([]);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    document.title = "Register Page";
    if (userStr !== null) {
      window.location.href = "#/home";
    }
    let formdata = {
      user_id: 1,
    };
    axios
      .post("https://backend.hssonline.com.au/getskills", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);

        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  }, []);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }

    let formdata = {
      first_name: vll.first_name.value,
      middle_name: vll.middle_name.value,
      last_name: vll.last_name.value,
      email: vll.email.value,
      password: vll.password.value,
      contact: vll.contact.value,
      address: vll.address.value,
      skills: skidata,
      years: vll.years.value,
    };
    if (vll.password.value != vll.confirm_password.value) {
      setShowPass(true);
      setShowPass({ type: "error" });
    } else {
      //console.log(formdata);
      axios
        .post("https://backend.hssonline.com.au/register", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "2") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
          } else {
            setShowAlert(true);
            setShowAlert({ type: "success" });
            event.target.reset();
            event.target.text();
            event.target.select();
          }
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {
          //console.log(err.response.data);
        });
    }
  };
  console.log(selectedOptions);
  const options = selectedOptions;
  console.log(options);
  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions);
    setselectvalue(selectedOptions);
    //setSelectedOptions(selectedOptions);
  };

  const handleRemoveOption = (removedOption) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option.value !== removedOption.value)
    );
  };

  const selectedOptionLabels = selectedOptions.map(
    (selectedOption) => selectedOption.value
  );
  return (
    <>
      <header>
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-9 col-md-4">
              <div className="navbar-list d-flex gap-3 justify-content-end">
                <Link to="/login">Login</Link>
                <Link to="/register" className="active">
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="fullscreen d-flex justify-content-center align-items-center mt-4">
        <div className="d-flex flex-column gap-3">
          <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600 mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex flex-column gap-2">
                <h3 className="fs-30 fw-bold text-center mytext-primary">
                  Register
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Register successfully"
                    closable
                  />
                )}
                <form action="" onSubmit={handleSubmit} method="post">
                  <div className="d-flex flex-column gap-3">
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        First Name <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-user top-icon"></i>
                        <input
                          type="text"
                          onChange={handleInput}
                          name="first_name"
                          required
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        Middle Name(Optional)
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-user top-icon"></i>
                        <input
                          type="text"
                          name="middle_name"
                          onChange={handleInput}
                          placeholder="Middle Name"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Last Name <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-user top-icon"></i>
                        <input
                          type="text"
                          onChange={handleInput}
                          name="last_name"
                          required
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Phone Number{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-phone-alt top-icon"></i>
                        <input
                          type="text"
                          name="contact"
                          onChange={handleInput}
                          required
                          pattern="[0-9]*"
                          minlength="10"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Email-Id <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-envelope top-icon"></i>
                        <input
                          type="email"
                          name="email"
                          onChange={handleInput}
                          required
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Password <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-lock top-icon"></i>
                        <input
                          type="password"
                          name="password"
                          onChange={handleInput}
                          required
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Re-Enter Password{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-lock top-icon"></i>
                        <input
                          type="password"
                          name="confirm_password"
                          onChange={handleInput}
                          required
                          placeholder="Re-Enter Password"
                        />
                        <div className="mt-2">
                          {showPass?.type === "error" && (
                            <Alert
                              type="error"
                              description="Password does not match"
                              closable
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Address <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-location top-icon"></i>
                        <input
                          type="text"
                          name="address"
                          onChange={handleInput}
                          required
                          placeholder="Address"
                        />
                      </div>
                    </div>
                    <h5 class="mt-2  text-white">
                      Skill and Extra Informations
                    </h5>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Mention All Skills{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <Select
                          isMulti
                          options={options}
                          defaultValue={options}
                          name="skills"
                          required
                          onChange={handleSelectChange}
                        />
                        <div></div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2  text-white">
                        Years of Experience{" "}
                        <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <input
                          type="number"
                          pattern="[0-9]*"
                          name="years"
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Register successfully"
                    closable
                  />
                )}
                <div className="d-flex justify-content-center align-items-center my-3">
                  <div className="flex flex-column gap-4 w-md-300">
                    <h3 className="fs-18 fw-bold text-center mytext-primary">
                      <Link to="/login" className="mytext-primary">
                        Login
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="ft-topbox d-block py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Contact us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>Address: 8 Sparks Rd, Henderson WA 6166 </p>
                    <p>
                      <Link to="javascript:void(0)">Phone: +61 894375388</Link>{" "}
                    </p>
                    <p>Hours: 7:00am - 5:00pm Monday - Friday </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Help</h3>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/contact">Contact Information</Link>{" "}
                        </p>
                        <p>
                          <Link to="javascript:void(0)">How to Use</Link>{" "}
                        </p>
                        <p>
                          <Link to="/experthelp">Get Expert Help</Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/terms&condition">
                            Terms and Conditions
                          </Link>{" "}
                        </p>
                        <p>
                          <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>exprole us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>
                      <Link to="www.hssonline.com.au">
                        www.hssonline.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="www.hssptyltd.com.au">
                        www.hssptyltd.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="www.jlme.com.au">www.jlme.com.au </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-ft py-2 text-center">
          <div className="container-lg">
            <p>
              Privacy | Site Terms | &copy; {currentYear}, JL Mining All rights
              reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
