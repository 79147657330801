import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";

export const AdminLogin = () => {
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("admin"));
    console.log(userStr);
    if (userStr != null) {
      navigate("/admin/notification");
    }
  }, []);

  const [showAlert, setShowAlert] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      password: vll.password.value,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/login", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          sessionStorage.setItem("admin", JSON.stringify(res.data.status));
          setShowAlert(true);
          setShowAlert({ type: "success" });
          const items = JSON.parse(sessionStorage.getItem("admin"));
          console.log("admin");
          console.log(items);
          //event.target.reset();
          //(event.target).text()
          navigate("/admin/notification");
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  return (
    <>
      <div>
        <header>
          <div className="container-lg">
            <div className="row gy-4 align-items-center justify-content-between">
              <div className="col-3 col-md-2">
                <Link to="#" className="logo-top">
                  <img
                    src={require("../assets/admin/images/logo2.png")}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col-md-2 d-none d-md-block">
                <Link to="#" className="logo-top">
                  <img
                    src={require("../assets/admin/images/hss.png")}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col-9 col-md-4">
                <div className="navbar-list d-flex gap-3 justify-content-end">
                  <Link to="/admin/login" className="active">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="fullscreenadmin d-flex justify-content-center align-items-center mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600 mt-4">
              <div className="d-flex justify-content-center align-items-center">
                <div className="flex flex-column gap-2">
                  <h3 className="fs-30 fw-bold text-center mytext-primary">
                    Login
                  </h3>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  {showAlert?.type === "error" && (
                    <Alert
                      type="error"
                      description="Invalid detail!"
                      closable
                    />
                  )}
                  {showAlert?.type === "success" && (
                    <Alert
                      type="success"
                      description="Login successfully"
                      closable
                    />
                  )}
                  <form action="" onSubmit={handleSubmit} method="post">
                    <div className="d-flex flex-column gap-3">
                      <div className="mt-2">
                        <label className="mb-2 text-white">
                          Email-Id <span className="text-danger fs-16">*</span>
                        </label>
                        <div className="form-box rel">
                          <i className="fal fa-envelope top-icon"></i>
                          <input
                            type="text"
                            name="email"
                            required
                            placeholder="Email-Id"
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label className="mb-2 text-white">
                          Password <span className="text-danger fs-16">*</span>
                        </label>
                        <div className="form-box rel">
                          <i className="fal fa-lock top-icon"></i>
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                          />
                        </div>
                      </div>
                      <div>
                        <button type="submit" className="submit-btn">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>

                  {/* <div className="d-flex justify-content-center align-items-center my-3">
                    <div className="flex flex-column gap-4 w-md-300">
                      <h3 className="fs-18 fw-bold text-center mytext-primary">
                        <Link to="" className="mytext-primary">
                          Forgot Password
                        </Link>
                      </h3>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
