import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/admin/css/custom-style.css";
import "../assets/admin/css/main.css";
import { AdminNotification } from "./AdminNotification";
import { AdminClient } from "./AdminClient";
import { FaSignOutAlt } from "react-icons/fa";
const AdminHeader = () => {
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.removeItem("admin");
    window.location.href = "/admin/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const isMobileScreen = window.innerWidth <= 767.98;
  const [isDivVisible, setDivVisibility] = useState(!isMobileScreen);
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    const handleResize = () => {
      const isMobile = window.innerWidth <= 767.98;
      if (isMobile !== isDivVisible) {
        setDivVisibility(!isMobile);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDivVisible]);

  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
  };
  return (
    <header className="admin-header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-8 col-md-3">
            <div className="d-flex gap-5 align-items-center">
              <div className="nav-mob d-block d-md-none">
                <button type="button" onClick={toggleDivVisibility}>
                  <i className="fal fa-bars"></i>
                </button>
              </div>
              <Link to="#" className="logo-box fulw d-inline-block">
                <img
                  src={require("../assets/admin/images/logo2.png")}
                  alt="image"
                />
              </Link>
              <Link to="#" className="logo-box fulw d-inline-block">
                <img
                  src={require("../assets/admin/images/hss.png")}
                  alt="image"
                />
              </Link>
            </div>
          </div>
          <div className="col-4 col-md-9">
            <div className="d-flex gap-2 align-items-center justify-content-end">
              <div className="user-box rel">
                <button className="d-flex gap-2 align-items-center">
                  <span className="user-admin-img ofit">
                    <img
                      src={require("../assets/admin/images/user.png")}
                      alt="image"
                    />
                  </span>
                  <h6 className="user-text-admin">Admin Name</h6>
                </button>
                <div className="drop-user-links mt-2 text-center">
                  <Link to="/admin/logout">
                    <FaSignOutAlt /> Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Admin Header content */}

      <div
        className="navbar-top"
        style={{ display: isDivVisible ? "block" : "none" }}
      >
        <div className="d-flex flex-column">
          <div className="nav-link-main d-flex flex-column">
            <Link
              className={
                location.pathname === "/admin/notification" ? "active" : ""
              }
              to="/admin/notification"
            >
              Notification center
            </Link>
            <Link
              className={
                location.pathname === "/admin/timesheet" ? "active" : ""
              }
              to="/admin/timesheet"
            >
              Time Sheet
            </Link>
            <Link
              className={
                location.pathname === "/admin/employee" ||
                location.pathname === "/admin/addemployee"
                  ? "active"
                  : ""
              }
              to="/admin/employee"
            >
              Employees
            </Link>
            <Link
              className={
                location.pathname === "/admin/client" ||
                location.pathname === "/admin/addclient"
                  ? "active"
                  : ""
              }
              to="/admin/client"
            >
              Upload clients
            </Link>
            <Link
              className={
                location.pathname === "/admin/location" ||
                location.pathname === "/admin/addlocation"
                  ? "active"
                  : ""
              }
              to="/admin/location"
            >
              Upload locations
            </Link>
            <Link
              className={
                location.pathname === "/admin/addskill" ||
                location.pathname === "/admin/addskill"
                  ? "active"
                  : ""
              }
              to="/admin/addskill"
            >
              Add Skill
            </Link>
            <Link to="/admin/logout">
              <FaSignOutAlt /> Logout
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
