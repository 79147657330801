import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Alert } from "antd";
import moment from "moment";
import Modal from "react-modal";
import { FaStar } from "react-icons/fa";
import { FaUmbrella } from "react-icons/fa";
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import BigCalendar from "react-big-calendar";

export const Cl = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [valueget, setValueget] = useState([]);
  const [valueroster, setValueroster] = useState([]);
  const [clientname, setClientname] = useState("");
  const [locatename, setlocatename] = useState("");
  const [daystatus, setdaystatus] = useState("Attendance");
  const [sel, setSelect] = useState("Present");

  const handlePopupTextChange = (event) => {
    setPopupText(event.target.value);
  };
  const handleCheckboxChange = (event) => {
    var ch = event.target.value;
    setSelect(ch);
  };
  const isRequired = () => {
    // Add your conditions here based on the selected option value
    console.log();
    if (sel === "Sick Leave") {
      return false;
    }
    if (sel === "AL") {
      return false;
    }
    if (sel === "Present") {
      return true;
    }
    if (sel === "") {
      return true;
    }
  };
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const [showAlert, setShowAlert] = useState(false);
  const numbers = Array.from({ length: 24 }, (_, index) => index + 1);
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    document.title = "Current Roster Page";
    const userStr = JSON.parse(sessionStorage.getItem("users"));

    if (!userStr) {
      navigate("/login");
    }

    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/getroster", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        const fn = [];
        for (let i = 0; i < alldata.length; i++) {
          fn.push(alldata[i].type);
        }
        setValueget(fn);
        console.log(fn);
      })
      .catch((err) => {});

    axios
      .post("https://backend.hssonline.com.au/admin/getallroster", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        setValueroster(alldata);
      })
      .catch((err) => {});

    axios
      .post("https://backend.hssonline.com.au/admin/daystatus", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row;
        if (alldata.length) {
          setdaystatus(alldata[0].status);
        }
      })
      .catch((err) => {});
  }, []);
  const handle_getClientlocate = (event) => {
    let formdata = {
      clientId: event.target.value,
    };
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getlocateroster",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.results;
        console.log(alldata);
        setlocatename(alldata);
      })
      .catch((err) => {});
  };
  const handle_getClient = (event) => {
    let formdata = {
      clientId: event.target.value,
    };
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getclient_check",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.results;
        console.log(alldata);
        setClientname(alldata);
        setlocatename([]);
      })
      .catch((err) => {});
  };
  const handleAttend = (event) => {
    var fr = event.target;
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    var sf = fr.shift.value;
    if (fr.shift.value === "") {
      var sf = "Empty";
    }
    let formvl = {
      clientId: fr.clientId.value,
      location: fr.location.value,
      roster: fr.roster.value,
      user_id: userStr[0].id,
      shift: sf,
      hour: fr.hour.value,
      status: fr.status.value,
    };
    console.log(formvl);
    axios
      .post("https://backend.hssonline.com.au/admin/attendancesave", formvl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }
        setTimeout(() => {
          window.location.reload();
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  const events = [
    {
      icon: <FaStar />, // Custom icon for the event
      title: "Absent",
      start: new Date(2023, 6, 25),
      end: new Date(2023, 6, 25),
      color: "#ff0000", // Custom color for the event
      text: "Additional text for Event 1", // Custom text for the event
    },
    {
      icon: <FaStar />, // Custom icon for the event
      title: "Present",
      start: new Date(2023, 6, 25),
      end: new Date(2023, 6, 25),
      color: "black", // Custom color for the event
      text: "Additional text for Event 1", // Custom text for the event
    },
    {
      icon: <FaUmbrella />, // Custom icon for the event
      title: "Event 2",
      start: new Date(2023, 6, 28),
      end: new Date(2023, 6, 28),
      color: "yellow", // Custom color for the event
      text: "Additional text for Event 2", // Custom text for the event
    },
  ];
  const CustomEvent = ({ event }) => {
    const eventStyles = {
      display: "flex",
      alignItems: "center",
      color: "white", // Change the text color of the event title
      backgroundColor: "#3174ad", // Change the background color of the event
      padding: "4px",
      borderRadius: "4px",
    };

    const iconStyles = {
      marginRight: "8px", // Add some spacing between the icon and title
      color: event.color, // Change the color of the icon
    };
    return (
      <div style={iconStyles}>
        {event.icon} {event.title}
      </div>
    );
  };
  const handleSelectEvent = (event) => {
    console.log(daystatus);
    console.log(event);
    setSelectedEvent(event);
    if (daystatus == "Attendance") {
      setIsModalOpen(true);
    }
  };
  console.log(new Date());

  return (
    <>
      <header>
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="" className="logo-top">
                <img src={require("./assets/images/logo1.jpg")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-9 col-md-4">
              <div className="navbar-list d-flex gap-3 justify-content-end">
                <Link to="/home">Home</Link>
                <Link to="/profile">Profile</Link>
                <Link to="/newroster" className="active">
                  Roster
                </Link>
                <Link to="/timesheet">Time-Sheet</Link>
                <button className="logout" type="button" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="text-center py-5 d-block mt-5">
        <div className="d-block pt-5">
          <div className="container-lg">
            <div className="row gy-4"></div>
          </div>
        </div>

        {/* section */}
        <div className="d-block mt-4">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div
                style={{
                  height: "100vh",
                  backgroundColor: "#f8cdcd",
                  color: "black",
                }}
              >
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  selectable
                  onSelectEvent={handleSelectEvent}
                  components={{
                    event: CustomEvent, // Use the custom event component
                  }}
                  style={{ height: 500 }}
                />
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={() => setIsModalOpen(false)}
                  contentLabel="Add Text Modal"
                  className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
                >
                  {" "}
                  <form
                    action="javascript:void(0)"
                    onSubmit={handleAttend}
                    method="post"
                  >
                    {showAlert?.type === "error" && (
                      <Alert
                        type="error"
                        description="Already Exist"
                        closable
                      />
                    )}
                    {showAlert?.type === "success" && (
                      <Alert
                        type="success"
                        description="Successfully created!"
                        closable
                      />
                    )}
                    <h4 className="mb-2"> Choose Roster For Attendance </h4>
                    {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                    <label className="mb-2">Client Name</label>
                    <select
                      className="form-control mb-2"
                      required
                      onChange={handle_getClient}
                      name="clientId"
                    >
                      <option value={""}>Select value</option>
                      {Array.isArray(valueroster) ? (
                        valueroster.map((item, index) => (
                          <option value={item.client_id}>{item.name}</option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>
                    <label className="mb-2">Mine Site</label>
                    <select
                      className="form-control mb-2"
                      required
                      onChange={handle_getClientlocate}
                      name="location"
                    >
                      <option value={""}>Select value</option>
                      {Array.isArray(clientname) ? (
                        clientname.map((item, index) => (
                          <option value={item.id}>{item.location_name}</option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>
                    <label className="mb-2">Roster</label>
                    <select
                      className="form-control mb-2"
                      required
                      name="roster"
                    >
                      <option value={""}>Select value</option>
                      {Array.isArray(locatename) ? (
                        locatename.map((item, index) => (
                          <option value={item.type}>{item.type}</option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>

                    <label className="mb-2">Choose Status</label>
                    <select
                      className="form-control mb-2"
                      onChange={handleCheckboxChange}
                      required
                      name="status"
                    >
                      <option value={""}>Choose Status</option>
                      <option value={"Present"}>Present</option>
                      <option value={"Sick Leave"}>Sick Leave</option>
                      <option value={"AL"}>Annual Leave</option>
                    </select>
                    <label className="mb-2">Shift</label>
                    <select
                      className="form-control mb-2"
                      required={isRequired()}
                      name="shift"
                    >
                      <option value={""}>Select value</option>
                      <option value={"Day"}>Day Shift</option>
                      <option value={"Night"}>Night Shift</option>
                      <option value={"FLIAM"}>Fly in AM</option>
                      <option value={"FLIPM"}>Fly in PM</option>
                      <option value={"FLOAM"}>Fly out AM</option>
                      <option value={"FLOPM"}>Fly in PM</option>
                    </select>

                    <label className="mb-2">Choose Hours</label>
                    <select
                      className="form-control mb-2"
                      required={isRequired()}
                      name="hour"
                    >
                      <option value={""}>Choose Hours</option>
                      {numbers.map((number) => (
                        <option value={number}>{number}</option>
                      ))}
                    </select>
                    <button className="btn btn-success" type="submit">
                      Submit
                    </button>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        {/* section */}
      </section>
    </>
  );
};
