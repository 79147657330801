import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminLocation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [gdata, getData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const handlegetLocation = (locationId, index) => {
    let formdata = {
      locationId: locationId,
    };
    setActiveIndex(index);
    axios
      .post("https://backend.hssonline.com.au/admin/getidlocation", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.locations);
        getData(res.data.locations);
        setIsClicked(true);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const handlelocationRemove = (locationid) => {
    console.log(locationid);
    let location_id = {
      location_id: locationid,
    };
    const del = window.confirm(
      "Are you sure you want to delete this location?"
    );
    if (del) {
      axios
        .post(
          "https://backend.hssonline.com.au/admin/locationdelete",
          location_id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setIsClicked(false);
          setActiveIndex(null);
          axios
            .get("https://backend.hssonline.com.au/admin/getlocation", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    document.title = "Location Page";
    const userStr = JSON.parse(sessionStorage.getItem("admin"));
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://backend.hssonline.com.au/admin/getlocation", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row">
              <div className="float-end d-flex justify-content-end">
                <Link to="/admin/addlocation" className="addclient">
                  Add Location
                </Link>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="User deleted successfully!"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Mining Locations</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(data) ? (
                        data.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            className={`item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetLocation(item.id, index)}
                          >
                            {item.location_name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isClicked && (
                <div className="col-md-8">
                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Location Name (Address):-</label>
                          <span className="fs-14">{gdata.location_name}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Nearest Town:-</label>
                          <span className="fs-14">{gdata.nearest_town}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Client Name:-</label>
                          <span className="fs-14">{gdata.client_name}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Commodity:-</label>
                          <span className="fs-14">{gdata.commodity}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Contract Type:-</label>
                          <span className="fs-14">{gdata.contract_type}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Contract Duration:-</label>
                          <span className="fs-14">
                            {gdata.duration_start} to {gdata.duration_end}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Scope:-</label>
                          <span className="fs-14">{gdata.scope}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="addclient"
                            onClick={() => handlelocationRemove(gdata.id)}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
