import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Home = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [valueUserDetail, setValuegetuser] = useState([]);
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    console.log("ch");
    console.log(userStr);
    if (userStr === null) {
      console.log("cc");
      window.location.href = "#/login";
    }
    document.title = "Home Page";
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/getuserdetails", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        setValuegetuser(alldata);
      })
      .catch((err) => {});
  }, []);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      first_name: vll.first_name.value,
      middle_name: vll.middle_name.value,
      last_name: vll.last_name.value,
      email: vll.email.value,
      password: vll.password.value,
      contact: vll.contact.value,
      address: vll.address.value,
      skills: vll.skills.value,
      years: vll.years.value,
    };
    if (vll.password.value != vll.confirm_password.value) {
      setShowPass(true);
      setShowPass({ type: "error" });
    } else {
      //console.log(formdata);
      axios
        .post("https://backend.hssonline.com.au/register", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "2") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
          } else {
            setShowAlert(true);
            setShowAlert({ type: "success" });
            event.target.reset();
            event.target.text();
          }
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {
          //console.log(err.response.data);
        });
    }
  };
  const fname = valueUserDetail.first_name;
  const mname = valueUserDetail.middle_name;
  const lname = valueUserDetail.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  return (
    <>
      <Header />
      <div className="fullscreen d-flex justify-content-center align-items-center h-100">
        <div className="d-flex flex-column gap-3">
          <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="d-flex flex-column  gap-3 mt-4">
                  <div className="btn-red-block">
                    <div className="d-flex justify-content-between gap-2">
                      <Link to="/currentroster">Current Roster</Link>
                    </div>
                  </div>
                  <div className="btn-red-block">
                    <div className="d-flex justify-content-between gap-2">
                      <Link to="/profile">Profile</Link>
                      <Link to="/timesheet">Signature</Link>
                    </div>
                  </div>
                  <div className="btn-red-block w-50 m-auto">
                    <Link to="/clinetinformation">Client Information</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
