import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
export const Logout = () => {
  sessionStorage.removeItem("users");
    window.location.href = "#/login";
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [showssheader, setshowssheader] = useState(false);
  
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    sessionStorage.removeItem("users");
    window.location.href = "#/login";
  }, []);
  
  return (
    <>
     
    </>
  );
};
