import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminNotification = () => {
  const navigate = useNavigate();
  const [valuenotifications, setValuegetnotifications] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Notification Page";
    const userStr = JSON.parse(sessionStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {};
    axios
      .post(
        "https://backend.hssonline.com.au/admin/getallnotifications",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        var alldata = res.data.data;
        setValuegetnotifications(alldata);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-12">
                <div className="right-form-box bg-white shadow p-2 ">
                  <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                    Notification Center
                  </h5>
                  <div className="block scroll-text-box px-4">
                    {Array.isArray(valuenotifications) ? (
                      valuenotifications.map((item, index) => (
                        <div className="  gap-2 mt-3">
                          <span className="fw-800">
                            {" "}
                            {item.first_name} {item.middle_name}{" "}
                            {item.last_name}
                          </span>{" "}
                          {item.message} on {item.nd}
                        </div>
                      ))
                    ) : (
                      <Link> No data available </Link>
                    )}
                  </div>

                  <span className="fs-20 text-center"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
