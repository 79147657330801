import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Experthelp = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [val, setVal] = useState("");
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    console.log(userStr);
    console.log("chk");
    document.title = "Get Expert Help Page";
  }, []);
  const handleSubmit = (event) => {
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      address: vll.address.value,
      phone: vll.phone.value,
    };
  };

  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">Get Expert Help</h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column gap-2">
                <h5 className="py-2 px-2 text-secondary lh-base">
                  If you require expert assistance or have specific inquiries
                  related to the Roster and Time-Sheet Website, we offer
                  professional support to help you navigate and optimize your
                  experience. Our team of experts is here to assist you with
                  various aspects of the website, including technical issues,
                  data management, and policy-related questions.
                </h5>
              </div>
            </div>
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column">
                <h3 className="fs-25 fw-800 text-black">
                  1. Technical Support:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  If you encounter technical difficulties while using the
                  website, such as login issues, error messages, or
                  functionality problems, our technical support team is
                  available to assist you. You can reach out to us through the
                  following channels:
                </h5>{" "}
                <span className="fs-20">
                  <strong> Email Support:</strong> support@jlmining.com.au
                </span>
                <span className="fs-20 mt-2">
                  <strong> Phone Support:</strong> +61 894375388
                </span>
              </div>
            </div>
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column">
                <h3 className="fs-25 fw-800 text-black">
                  2. Data Management and Policy Questions:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  For inquiries related to data management, privacy concerns, or
                  questions about our Terms and Conditions and Privacy Policy,
                  our experts can provide guidance and clarification. Contact us
                  via:
                </h5>
                <span className="fs-20">
                  <strong> Email:</strong> support@jlmining.com.au
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex flex-column">
                <h3 className="fs-25 fw-800 text-black">
                  3. General Inquiries:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  If you have general questions or need information about using
                  the website, its features, or any other non-technical matters,
                  our team is here to help. Feel free to contact us at:
                </h5>
                <span className="fs-20">
                  <strong> General Inquiry Email::</strong>{" "}
                  support@jlmining.com.au
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex flex-column">
                <h3 className="fs-25 fw-800 text-black">
                  4. Website Support Portal:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  You can also access our support portal directly through the
                  "Support" or "Contact Us" section on the Roster and Time-Sheet
                  Website. This portal may provide helpful resources, FAQs, and
                  additional ways to get in touch with us. Our goal is to ensure
                  that your experience with the Roster and Time-Sheet Website is
                  as smooth and efficient as possible. Whether you need
                  technical assistance, have policy-related queries, or simply
                  require guidance, our expert team is committed to providing
                  you with the support you need.
                  <br />
                  We appreciate your trust in our services and look forward to
                  assisting you with any questions or concerns you may have.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
