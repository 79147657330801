import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";

export const AdminAddskill = () => {
  const [isActive, setIsClicked] = useState(false);
  const [isActiveno, setIsClickedno] = useState(false);
  const [indx, setindx] = useState(null);
  const [emData, setData] = useState([]);
  const [timesheet, setDataTimesheet] = useState([]);
  const [empnamevalue, empname] = useState("");
  const [empstartdatevalue, empstartdate] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;

    let formdata = {
      skills: vll.skills.value,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/addskill", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .get("https://backend.hssonline.com.au/admin/getallskill", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          event.target.reset();
          event.target.text();
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Time Sheet Page";
    const userStr = JSON.parse(sessionStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://backend.hssonline.com.au/admin/getallskill", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  //Get User Time Sheet
  const handlegettimesheet = (event, index, f, m, l) => {
    setindx(index);
    var n = f + " " + m + " " + l;
    empname(n);
    let user_id = {
      user_id: event,
    };
    axios
      .post("https://backend.hssonline.com.au/admin/getTimesheet", user_id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          if (res.data.data !== "") {
            empstartdate(res.data.data[0].nd);
            setIsClickedno(false);
            setIsClicked(true);
            console.log(res.data.data);
            setDataTimesheet(res.data.data);
          }
        } else {
          setIsClickedno(true);
          setIsClicked(false);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">All Skill</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <Link to="javascript:void(0)">{item.value}</Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Skill has been added successfully"
                    closable
                  />
                )}
                <div className="right-form-box bg-white shadow p-2">
                  <form action="" onSubmit={handleSubmit} method="post">
                    <div className="row gy-2">
                      <div className="col-12 col-lg-4">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Skill <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            onChange={handleInput}
                            placeholder="type here"
                            name="skills"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button className="sub-btn">add</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
